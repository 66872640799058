'use client';

import { useCallback, useEffect, useState } from 'react';

type Props<T, P> = {
  params?: P;
  fn: (params?: P) => Promise<T>;
  skip?: boolean;
  onCompleted?: (data: T) => void;
};

export function useQuery<T, P = unknown>({
  fn,
  skip,
  params,
  onCompleted,
}: Props<T, P>) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchData = useCallback(async () => {
    if (skip) return;
    setLoading(true);
    try {
      const result = await fn(params);
      setData(result);
      setError(null);
      onCompleted?.(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, skip]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error };
}
