import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/node_modules/.pnpm/next-intl@3.25.1_next@14.2.5_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/node_modules/.pnpm/next-intl@3.25.1_next@14.2.5_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/node_modules/.pnpm/next-intl@3.25.1_next@14.2.5_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/src/hooks/disclosure.hook.tsx");
;
import(/* webpackMode: "eager" */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/src/hooks/progress-control.hook.ts");
;
import(/* webpackMode: "eager" */ "/Users/lochuynhquang/Documents/digital-falcon/vinh-an-landing/src/hooks/useQuery.hook.ts");
