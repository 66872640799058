'use client';
import { useState } from 'react';

type Props = {
  numSteps: number;
};

export function useProgressControl({ numSteps }: Props) {
  const stepLength = 100 / (numSteps + 2);
  const [progress, setProgress] = useState(stepLength);

  const nextStep = () => {
    setProgress((prev) => Math.max(100, prev + 100 / (numSteps + 2)));
  };

  return {
    progress,
    nextStep,
  };
}
